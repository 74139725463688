import "alpinejs"
import { Application } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

import ClientDashboardController from "./controllers/client/dashboard_controller"
import SalesOnboardingController from "./controllers/sales/onboarding_controller"
import TenantOnboardingController from "./controllers/tenant/onboarding_controller"
import TenantNavController from "./controllers/tenant/nav_controller"

const application = Application.start()
application.register("client-dashboard", ClientDashboardController)
application.register("sales-onboarding", SalesOnboardingController)
application.register("tenant-onboarding", TenantOnboardingController)
application.register("tenant-nav", TenantNavController)

Rails.start()

console.log("well hello there")
