import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  connect() {
    console.log("Hello from Tenant")

    tippy("#video-next", {
      content:
        "You need to click the button at the end of the video in order to proceed.",
    })
  }

  pwclose() {
    console.log("close that modal")

    var element = document.getElementById("pwmodal")
    element.classList.add("hidden")
  }

  pwopen() {
    var element = document.getElementById("pwmodal")
    element.classList.remove("hidden")
  }

  pwredirect() {
    window.open(
      "https://app.propertyware.com/pw/index.html#/login/tenant/nomadicrealestateinvestmentsllc",
      "_blank"
    )

    var element = document.getElementById("pwmodal")
    element.classList.add("hidden")
  }

  navopen() {
    var element = document.getElementById("mobilenav")
    element.classList.remove("hidden")
  }
}
