import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello from Tenant Nav")
  }

  pwredirect() {
    var element = document.getElementById("pwmodal")
    element.classList.remove("hidden")

    var element = document.getElementById("mobilenav")
    element.classList.add("hidden")
  }

  navclose() {
    var element = document.getElementById("mobilenav")
    element.classList.add("hidden")
  }
}
