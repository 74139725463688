import autocomplete from "autocompleter"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unitForm"]
  connect() {
    var input = document.getElementById("search-portfolios")

    autocomplete({
      input: input,
      className: "py-2 bg-gray-100 border border-gray-300",
      render: function (item) {
        var div = document.createElement("div")
        div.textContent = item.label
        div.classList.add("p-2")
        div.classList.add("hover:bg-gray-300")
        div.classList.add("cursor-pointer")
        return div
      },
      fetch: function (text, update) {
        fetch("/sales/portfolio_searches?text=" + text)
          .then((response) => response.json())
          .then((data) => update(data))
      },
      debounceWaitMs: 200,
      onSelect: function (item) {
        input.value = item.value
      },
    })

    console.log("welcome to the sales onboarding controller")
  }

  addUnitForm(event) {
    const [_data, _status, xhr] = event.detail
    let newForm = document.createElement("div")
    newForm.innerHTML = xhr.response
    document.getElementById("add-unit-button").remove()
    this.unitFormTarget.append(newForm)
  }
}
